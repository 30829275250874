<template>
  <va-card
    :title="$t('tanks.tanksActions')"
  >
    <template slot="actions">
      <va-button id="addTankButton" flat small icon="at-icon l_water_volume2" @click="setAction('add')">{{ $t('tanks.actions.add') }}</va-button>
    </template>

    <at-data-table
      ref="tank_actions_table"
      :apiMode="false"
      :tableData="actions"
      :fields="tableFields"
      :paginationPath="'pagination'"
      :filter-input-shown="false"
      :per-page-selector-shown="false"
    >
      <template slot="actionName" slot-scope="props">
        <p>{{ $t(`tanks.actions.${props.rowData.actionName}`) }}</p>
      </template>

      <template slot="destination" slot-scope="props">
        <span v-html="props.rowData.actionDetails.progress.destination.replace(', ', '<br>')" :style="{fontSize: '14px'}"/>
      </template>

      <template slot="progress" slot-scope="props">
        <va-progress-bar v-model="props.rowData.actionDetails.progress.percent">
          <span :style="{fontSize: '12px', visibility: props.rowData.actionDetails.progress.state !== 'Not running' ? 'visible' : 'hidden'}">
            {{ Math.round(props.rowData.actionDetails.progress.percent) }}%
          </span>
        </va-progress-bar>

        <div class="action-buttons">
          <va-button v-if="props.rowData.actionDetails.progress.state === 'Paused'" flat icon="at-icon l_player_play" @click="setAction('play', props.rowData)"/>
          <va-button v-if="props.rowData.actionDetails.progress.state === 'Running'" flat icon="at-icon l_player_pause" @click="setAction('pause', props.rowData)"/>
          <va-button v-if="props.rowData.actionDetails.progress.state === 'Running' || props.rowData.actionDetails.progress.state === 'Paused'" flat icon="at-icon l_player_stop" @click="setAction('stop', props.rowData)"/>
        </div>
      </template>

      <template slot="details" slot-scope="props">
        <template v-if="props.rowData.actionDetails.event_key">
          <template v-if="props.rowData.actionName.toLowerCase() === 'irrigate' || props.rowData.actionName.toLowerCase() === 'disinfection_tank'">
            <div class="pb-2"><p><b>{{ 'Volume: '}}</b>
              {{ `${Math.round(props.rowData.actionDetails.progress.val * 100) / 100} / ${Math.round(props.rowData.actionDetails.progress.total * 100) / 100}` }}
              {{ `${getUnitFormated(props.rowData.actionDetails.progress.units)}` }}</p>
            </div>
            <div><p><b>{{ 'Flow: '}}</b>
              {{ `${Math.round(props.rowData.actionDetails.volume_flow.val * 100) / 100} ${getUnitFormated(props.rowData.actionDetails.volume_flow.units)}` }}</p>
            </div>
          </template>

          <template v-else-if="props.rowData.actionName.toLowerCase() === 'circulate_tank'">
            <div class="pb-2"><p><b>{{ 'Volume: '}}</b>
              {{ `${Math.round(props.rowData.actionDetails.volume.val * 100) / 100} ${getUnitFormated(props.rowData.actionDetails.volume.units)}` }}</p>
            </div>
            <div><p><b>{{ 'Flow: '}}</b>
              {{ `${Math.round(props.rowData.actionDetails.volume_flow.val * 100) / 100} ${getUnitFormated(props.rowData.actionDetails.volume_flow.units)}` }}</p>
            </div>
          </template>

          <template v-else-if="props.rowData.actionName.toLowerCase() === 'empty_tank_to_waste'">
            <div class="pb-2"><p><b>{{ 'Volume: '}}</b>
              {{ `${Math.round(props.rowData.actionDetails.volume.val * 100) / 100} ${getUnitFormated(props.rowData.actionDetails.volume.units)}` }}</p>
            </div>
            <div><p><b>{{ 'Flow: '}}</b>
              {{ `${Math.round(props.rowData.actionDetails.volume_flow.val * 100) / 100} ${getUnitFormated(props.rowData.actionDetails.volume_flow.units)}` }}</p>
            </div>
          </template>

          <template v-else-if="props.rowData.actionName.toLowerCase() === 'fill_tank'">
            <div class="pb-2"><p><b>{{ 'Volume: '}}</b>
              {{ `${Math.round(props.rowData.actionDetails.volume.val * 100) / 100} ${getUnitFormated(props.rowData.actionDetails.volume.units)}` }}</p>
            </div>
            <div><p><b>{{ 'Flow: '}}</b>
              {{ `${Math.round(props.rowData.actionDetails.volume_flow.val * 100) / 100} ${getUnitFormated(props.rowData.actionDetails.volume_flow.units)}` }}</p>
            </div>
          </template>

          <template v-else-if="props.rowData.actionName.toLowerCase() === 'recalibrate_tank'">
            <div class="pb-2"><p><b>{{ 'Volume: '}}</b>
              {{ `${Math.round(props.rowData.actionDetails.volume.val * 100) / 100} ${getUnitFormated(props.rowData.actionDetails.volume.units)}` }}</p>
            </div>
            <div><p><b>{{ 'Flow: '}}</b>
              {{ `${Math.round(props.rowData.actionDetails.volume_flow.val * 100) / 100} ${getUnitFormated(props.rowData.actionDetails.volume_flow.units)}` }}</p>
            </div>
          </template>

          <template v-else-if="props.rowData.actionName.toLowerCase() === 'recover_tank'">
            <div class="pb-2"><p><b>{{ 'Volume: '}}</b>
              {{ `${Math.round(props.rowData.actionDetails.progress.val * 100) / 100} / ${Math.round(props.rowData.actionDetails.progress.total * 100) / 100}` }}
              {{ `${getUnitFormated(props.rowData.actionDetails.progress.units)}` }}</p>
            </div>
            <div><p><b>{{ 'Flow: '}}</b>
              {{ `${Math.round(props.rowData.actionDetails.volume_flow.val * 100) / 100} ${getUnitFormated(props.rowData.actionDetails.volume_flow.units)}` }}</p>
            </div>
          </template>

        </template>
      </template>

      <template slot="elapsed_time" slot-scope="props">
        <template v-if="props.rowData.actionDetails.event_key">
          <template v-if="props.rowData.actionDetails.progress.units === 's' || props.rowData.actionDetails.progress.units === 'sec'">
            <p>{{ getElapsedTime(props.rowData.actionDetails.progress.elapsed_time) }} / {{ getTotalTime(props.rowData.actionDetails.progress.total)}}</p>
          </template>
          <template v-else>
            <p>{{ getElapsedTime(props.rowData.actionDetails.progress.elapsed_time) }}</p>
          </template>
        </template>
      </template>

      <template slot="status" slot-scope="props">
        <p>{{  props.rowData.actionDetails.progress.state }}</p>
      </template>

    </at-data-table>
  </va-card>
</template>

<script>
import moment from 'moment-timezone'
import { styleHelpers } from '@/services/atmosphere-ui'
import AtDataTable from '../at-data-table/AtDataTable.vue'

export default {
  components: { AtDataTable },
  name: 'tank-action-list',
  data () {
    return {
    }
  },
  props: {
    actions: {
      type: Object,
      default: () => {
        return { data: [] }
      },
    },
  },
  computed: {
    tableFields () {
      return [
        {
          name: '__slot:actionName',
          title: 'Action',
          titleClass: 'text-left',
          width: '13%',
        },
        {
          name: '__slot:destination',
          title: 'Destination',
          titleClass: 'text-center',
          dataClass: 'text-center',
          width: '20%',
        },
        {
          name: '__slot:progress',
          title: 'Progress',
          titleClass: 'text-center',
          width: '20%',
        },
        {
          name: '__slot:details',
          dataClass: 'text-center',
          width: '15%',
        },
        {
          name: '__slot:elapsed_time',
          title: this.$t('timeData.elapsedTime'),
          titleClass: 'text-center',
          dataClass: 'text-center',
          width: '15%',
        },
        {
          name: '__slot:status',
          title: 'State',
          titleClass: 'text-center',
          dataClass: 'text-center',
          width: '20%',
        },
      ]
    },
  },
  mounted () {
  },
  methods: {
    getUnitFormated (units) {
      return styleHelpers.getOnlyUnitFormat(units)
    },
    getTotalTime (seconds) {
      if (seconds < 3600) {
        return moment.utc(seconds * 1000).format('mm:ss') + ' min'
      } else if (seconds >= 3600) {
        return moment.utc(seconds * 1000).format('HH:mm') + 'H'
      } else {
        return seconds
      }
    },
    getElapsedTime (elapsedTime) {
      const time = elapsedTime.split(' (')[0]
      const timeLenght = time.split(':').length

      if (timeLenght <= 2) {
        return time + ' min'
      } else if (timeLenght > 2) {
        return time + 'H'
      } else {
        return time
      }
    },
    setAction (selectedAction, action) { // add, play, pause and stop
      if (selectedAction === 'add') {
        this.$emit('setAction', {
          action: 'add',
        })
      } else {
        this.$emit('setAction', {
          action: selectedAction,
          actionName: action.actionName,
          actionKey: action.actionDetails.event_key,
        })
      }
    },
  },
  watch: {
    actions () {
      this.$refs.tank_actions_table.$refs.vuetable.refresh()
    },
  },
}
</script>

<style lang="scss">
.va-card__header-actions .va-button .at-icon {
  padding-right: 0.5rem;
}

#addTankButton {
  i {
    font-size: 1.3rem;
  }
}

.action-buttons {
  display: flex;
  justify-content: center;

  .va-button {
    margin: 0.2rem;
    font-size: 1.5rem;
  }
}

.va-modal {
  .va-modal__message {
    width: 700px;
  }
}
</style>
