<template>
  <div>
    <div v-if="loading" class="flex sm12 lg12">
      <div class="text--center pb-4">
        <div class="flex-center spinner-box">
          <spinner/>
        </div>
      </div>
    </div>
    <div v-if="tankDetails && !loading">
      <div class="row">
        <div class="flex xs9">
          <h1>{{ tankDetails.name }}</h1>
        </div>
      </div>

      <div class="cards">
        <div class="cards-container row d-flex wrap align--start">
          <!-- Tank Data -->
          <data-card v-for="(dataName, index) in dataNames" :key="index"
            :id="irrigationLineId"
            :data-name="dataName"
            :data-icon="getDataIcon(dataName)"
            :data-now-level="currentData[dataName].level"
            :data-now-val="currentData[dataName].val"
            :data-now-units="currentData[dataName].units"
            :data-now-ref="currentData[dataName].ref"
            :is-expandable="false"
          />

          <div class="flex xs12 sm12"/>

          <!-- Tank actions -->
          <div class="flex xs12 sm12">
            <at-tank-action-list class="mb-4 va-card-table" v-if="!actionsLoading"
              :actions="currentActionsDataTable"
              :actions-loading="actionsLoading"
              @setAction="setAction"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Modal start, pause and stop -->
    <va-modal
      ref="modal"
      v-model="showModal"
      :title="modalTitle"
      :message="modalMessage"
      :okText=" $t('modal.confirm') "
      :cancelText=" $t('modal.cancel') "
      @ok="confirmAction()"
      @cancel="closeModal"
    >
    </va-modal>
    <!-- Add new modal -->
    <va-modal
      ref="addmodal"
      v-model="showAddNewModal"
      :noOutsideDismiss="true"
      :hideDefaultActions="true"
      size="large"
    >

      <template v-slot:header>
        <h6>{{ modalSubTitle }}</h6>
        <h4>{{ modalTitle }}</h4>
      </template>

      <template>
        <at-irrigation-event-form
          ref="addNewIrrigationEventForm"
          :irrigation-line-id="irrigationLineId"
          :add-event="true"
          :irrigation-possible-events="tankPossibleActions"
          :control-groups="controlGroupsData"
          :tanks="groupedTanks"
          :mixingTanks="[]"
          :all-tanks-grouped="groupedTanks"
        />
      </template>

      <!-- remove this -->

      <!-- / remove this -->

      <template slot="actions">
        <div class="row">
          <div class="flex xs12 text-right">
            <va-button @click="closeModal()" flat color="gray">{{ $t('modal.cancel') }}</va-button>
            <va-button @click="confirmAddAction()" color="success" class="mr-0">{{ $t('modal.confirm') }}</va-button>
          </div>
        </div>
      </template>

    </va-modal>
  </div>
</template>

<script>
import * as socketio from '../../../utilities/socketio'
import { styleHelpers } from '@/services/atmosphere-ui'
import { tanksActions } from '../../../data/tanksSettings'
import DataCard from '@/services/atmosphere-ui/components/cards/at-data-card/AtDataCard'
import AtTankActionList from '@/services/atmosphere-ui/components/at-tank-action-list/AtTankActionList'

export default {
  name: 'irrigation-tank-cards',
  data () {
    return {
      loading: true,
      actionsLoading: false,

      notFound: false,
      dataNames: ['ph', 'electrical_conductivity', 'orp', 'temperature', 'level', 'volume'],
      irrigationLineData: null,
      controlGroupsData: [],
      groupedTanks: {},

      tankDetails: {},
      currentData: {},

      selectedControlAction: '',
      selectedTankAction: '',
      selectedActionKey: '',
      selectedData: {
        room: '',
        side1: '',
        // side2: '',
        originTank: '',
        destinationTank: '',
        duration: '',
        volume: '',
        overfill: false,
        fertilizers: {},
      },

      tankPossibleActions: [],
      currentActionsData: {},
      currentActionsDataTable: { data: [] },
      showModal: false,
      showAddNewModal: false,
      modalTitle: '',
      modalSubTitle: '',
      modalMessage: '',
      side1Options: [],
      // side2Options: [],
    }
  },
  created () {
    this.$level.value = 'ground'
  },
  mounted () {
    this.initializeView()
    this.listenCurrentData()
  },
  computed: {
    activeFarm () {
      return this.$farmId ? this.$farmId : parseInt(process.env.VUE_APP_FARM_ID)
    },
    irrigationLineId () {
      return parseInt(this.$route.params.irrigationLineId)
    },
    tankId () {
      return parseInt(this.$route.params.tankId)
    },
  },
  methods: {
    getTankType () {
      return this.tankDetails.type
    },
    getDataIcon (dataName) {
      return styleHelpers.growingIcon('irrigation', dataName)
    },
    getUnitFormated (units) {
      return styleHelpers.getOnlyUnitFormat(units)
    },
    initializeView () {
      this.loading = true
      socketio.initialiseSocket()
      socketio.socket.emit('subscribe_to_context', [`irrigation_line_${this.irrigationLineId}`])
      socketio.socket.emit('get_one_tank', this.activeFarm, this.irrigationLineId, this.tankId)
      socketio.socket.once('get_one_tank_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
          this.notFound = true
        } else {
          this.tankDetails = parsed.data.data
          this.getTankPossibleActions()
          this.getOptions()
          this.initializeDataCards()
        }
      })
    },
    initializeDataCards () {
      for (let i = 0; i < this.dataNames.length; i++) {
        this.currentData = {
          ...this.currentData,
          [this.dataNames[i]]: {
            level: null,
            val: null,
            units: '',
            ref: null,
          },
        }
      }

      this.loading = false
    },
    listenCurrentData () {
      socketio.initialiseSocket()
      socketio.socket.emit('subscribe_to_context', [`tank_${this.tankId}`])
      socketio.socket.on('tank_water_conditions_current_update', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          const parsedData = parsed.data.data

          if (parsed.data.tank_id === this.tankId) {
            for (let i = 0; i < this.dataNames.length; i++) {
              if (this.dataNames[i] !== 'volume') {
                this.currentData[this.dataNames[i]] = parsedData[this.dataNames[i]]
              }
            }
            this.currentData.volume = {}
            if (this.currentData.level) {
              for (const field in this.currentData.level) {
                if (field === 'val') {
                  this.currentData.volume[field] = this.currentData.level[field] * this.tankDetails.capacity / 100
                } else if (field === 'units') {
                  this.$set(this.currentData.volume, field, 'm3')
                } else {
                  this.$set(this.currentData.volume, field, this.currentData.level[field])
                }
              }
            }
            this.currentActionsDataTable = {
              data: [],
            }

            const emptyAction = {
              event_key: '',
              pressure: {},
              progress: {
                destination: '',
                elapsed_time: '',
                origin: '',
                percent: 0,
                state: 'Not running',
                total: null,
                units: '',
                val: 0,
              },
              volume: {},
              volume_flow: {},
            }

            for (let i = 0; i < this.tankPossibleActions.length; i++) {
              this.currentActionsData[this.tankPossibleActions[i].actionName] = parsedData.events[this.tankPossibleActions[i].actionName] ? parsedData.events[this.tankPossibleActions[i].actionName] : emptyAction

              this.currentActionsDataTable.data.push(
                {
                  actionName: this.tankPossibleActions[i].actionName,
                  actionDetails: parsedData.events[this.tankPossibleActions[i].actionName] ? parsedData.events[this.tankPossibleActions[i].actionName] : emptyAction,
                },
              )
            }
          }

          this.actionsLoading = false
        }
      })
    },
    getTankPossibleActions () {
      this.tankPossibleActions = JSON.parse(JSON.stringify(tanksActions[this.getTankType()].data))
      for (let i = 0; i < this.tankPossibleActions.length; i++) {
        this.tankPossibleActions[i].selectionActionName = this.$t(`tanks.actions.${this.tankPossibleActions[i].actionName}`)
      }
    },
    getWaterHourlyData (start, end) { // INTEGRATE
      socketio.socket.emit('get_tank_water_conditions_hourly', this.tankId, { from: start, to: end })
      socketio.socket.on('get_tank_water_conditions_hourly_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          console.log('get_tank_water_conditions_hourly')
          console.log(parsed.data)
        }
      })
    },
    getWaterQuarterHourlyData (start, end) { // INTEGRATE
      socketio.socket.emit('get_tank_water_conditions_quarter_hourly', this.tankId, { from: start, to: end })
      socketio.socket.on('get_tank_water_conditions_quarter_hourly', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          console.log('get_tank_water_conditions_quarter_hourly')
          console.log(parsed.data)
        }
      })
    },
    getOptions () {
      socketio.initialiseSocket()
      socketio.socket.emit('get_one_irrigation_line', this.activeFarm, this.irrigationLineId)
      socketio.socket.on('get_one_irrigation_line_response', data => {
        const parsed = JSON.parse(data)
        if (parsed.status >= 400 && parsed.status < 500) {
          console.log(parsed.message)
        } else {
          if (parsed.data.id === this.irrigationLineId) {
            this.irrigationLineData = parsed.data.data
            this.controlGroupsData = (this.irrigationLineData.control_groups).sort((a, b) => (a.id > b.id ? 1 : -1))
            this.lineTanks = (this.irrigationLineData.tanks).sort((a, b) => (a.id > b.id ? 1 : -1))

            this.groupedTanks = this.lineTanks.reduce((groups, item) => ({
              ...groups,
              [item.type]: [...(groups[item.type] || []), item],
            }), {})
          }
        }
      })
    },
    setAction (value) {
      if (value.action === 'add') {
        this.addNewAction()
      } else {
        this.showModal = true
        this.modalTitle = this.$t(`tanks.actions.${value.actionName}`)
        this.modalMessage = this.$t(`tanks.actions.${value.actionName}_${value.action}_message`)

        this.selectedControlAction = value.action
        this.selectedTankAction = value.actionName
        this.selectedActionKey = value.actionKey ? value.actionKey : ''
      }
    },
    confirmAction () {
      // const emitEventName = `${this.selectedControlAction}_tank_event`
      const emitEventName = `${this.selectedControlAction}_irrigation`
      socketio.socket.emit(emitEventName, this.irrigationLineId, this.selectedActionKey)
      this.clearFields()
    },
    addNewAction () {
      this.clearFields()
      this.showAddNewModal = true
      this.selectedControlAction = 'add'

      this.modalTitle = this.$t('tanks.actions.add')
      this.modalMessage = ''
    },
    confirmAddAction () {
      this.$refs.addNewIrrigationEventForm.confirmAddAction()

      if (!this.addIrrigationEventFormReady) {
        console.log('review form')
      } else {
        const eventType = this.$refs.addNewIrrigationEventForm.selectedIrrigationEvent.actionName
        const data = this.$refs.addNewIrrigationEventForm.setAddNewActionData()
        const emitEventName = eventType === 'irrigate' ? 'add_irrigation' : `add_${eventType}_event`

        socketio.socket.emit(emitEventName, this.irrigationLineId, { data: data })

        this.launchToast(this.$t('tanks.actions.add') + ': ' + this.$t(`tanks.actions.${eventType}`))
        this.$refs.addIrrigationEventModal.ok()
      }
    },

    closeModal () {
      this.clearFields()
      this.$refs.modal.close()
      this.$refs.addmodal.close()
    },
    clearFields () {
      this.selectedControlAction = ''
      this.selectedTankAction = ''
      this.selectedActionKey = ''

      for (const data in this.selectedData) {
        this.selectedData[data] = data === 'overfill' ? false : ''
      }
    },
    launchToast (text) {
      this.showToast(
        text,
        {
          theme: 'none',
          position: 'top-center',
          duration: 4500,
          fullWidth: true,
        },
      )
    },
  },
  watch: {
    '$store.getters.getAppLanguage' () {
      this.getTankPossibleActions()
    },
    notFound () {
      if (this.notFound) {
        this.loading = false
        this.$router.push({ path: '/404/page-not-found' })
      }
    },
  },
  components: {
    DataCard,
    AtTankActionList,
  },
  beforeDestroy () {
    socketio.socket.emit('unsubscribe_from_context', [`irrigation_line_${this.irrigationLineId}`])
  },
}
</script>

<style lang="scss">
.va-modal {
  .va-modal__message {
    width: 700px;
  }

  .va-modal__header {
    margin-bottom: 15px;
  }

  .tank-setting {
    &:not(:last-child) {
      padding-right: 0.9rem;
    }

    .fertilizers:not(:last-child) {
      padding-right: 0.9rem;
    }

    .fertilizers_errors {
      color: rgb(195, 49, 49);
      vertical-align: middle;
      font-size: 0.8rem;
      margin-top: -1rem;
      text-align: center;
    }
  }

  .va-dropdown__content {
    min-height: 180px;
    font-size: pxtorem(14);
  }

  .va-input__container__label,
  .va-select__label {
    font-family: var(--font-family-body);
    text-transform: none;
    top: 0.25rem;
    margin-bottom: 0;
  }

  .va-select__input-wrapper {
    font-family: var(--font-family-body);
    font-size: pxtorem(14);
  }

  .va-input__container__input {
    font-family: var(--font-family-body);
    font-size: pxtorem(14);
  }

  .title {
    border-bottom: 2px solid;
    margin-bottom: 20px;

    h6 {
      @include radius(0 15px 0 0);

      width: 150px;
      display: inline-block;
      padding: 3px 20px 1px 10px;
      color: #ffffff;
      font-size: pxtorem(14);
      text-transform: uppercase;
      border-color: #94bdd1;
      background: #94bdd1;
    }
  }

  .min_ref_value,
  .max_ref_value {
    .va-input__container {
      border-color: #f5a455 !important;
    }
  }

  .ref_value {
    .va-input__container {
      border-color: var(--success-color) !important;
    }
  }

  .min_value,
  .max_value {
    .va-input__container {
      border-color: #ff3333 !important;
    }
  }
}

</style>
