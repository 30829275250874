export const tanksActions = {
  'mixing-tank': {
    data: [
      {
        id: 1,
        actionName: 'fill_tank',
        selectionActionName: '',
        actionSettings: {
          originTank: '',
          duration: 0,
          volume: 0.0,
        },
      },
      {
        id: 2,
        actionName: 'recalibrate_tank',
        selectionActionName: '',
        actionSettings: {
          originTank: '',
          duration: 0,
          volume: 0.0,
          ph: {
            phMin: '',
            phRefMin: '',
            phRef: '',
            phRefMax: '',
            phMax: '',
          },
          ec: {
            ecMin: '',
            ecRefMin: '',
            ecRef: '',
            ecRefMax: '',
            ecMax: '',
          },
        },
        // fertilizers: [
        //   {
        //     id: 1,
        //     tank_id: 11,
        //     description: 'Dosing Tank AGAB 001',
        //     ros_irrigation_id: '',
        //   },
        //   {
        //     id: 2,
        //     tank_id: 13,
        //     description: 'Dosing Tank ALGEM 002',
        //     ros_irrigation_id: '',
        //   },
        //   {
        //     id: 3,
        //     tank_id: 14,
        //     description: 'Dosing Tank AGAB 002',
        //     ros_irrigation_id: '',
        //   },
        //   {
        //     id: 4,
        //     tank_id: 16,
        //     description: 'Dosing Tank ALGEM 001',
        //     ros_irrigation_id: '',
        //   },
        // ],
      },
      {
        id: 3,
        actionName: 'send_to_tank',
        selectionActionName: '',
        actionSettings: {
          originTank: '',
          destinationTank: '',
          duration: 0,
          volume: 0.0,
        },
        destinationTankOptions: [
          {
            id: 1,
            description: 'Room 1',
            ros_irrigation_id: 'room1',
          },
          {
            id: 2,
            description: 'Room 2',
            ros_irrigation_id: 'room2',
          },
          {
            id: 3,
            description: 'Waste',
            ros_irrigation_id: 'waste',
          },
        ],
      },
      {
        id: 4,
        actionName: 'circulate_tank',
        selectionActionName: '',
        actionSettings: {
          originTank: '',
          duration: 0,
          volume: 0.0,
        },
      },
    ],
  },
  'drainage-tank': {
    data: [
      {
        id: 1,
        actionName: 'irrigate',
        selectionActionName: '',
        actionSettings: {
          originTank: '',
          room: '',
          side1: '',
          duration: 0,
          volume: 0.0,
        },
      },
      // {
      //   id: 2,
      //   actionName: 'fill_tank',
      //   selectionActionName: '',
      //   actionSettings: {
      //     originTank: '',
      //     duration: 0,
      //     volume: 0.0,
      //   },
      // },
      // {
      //   id: 3,
      //   actionName: 'send_to_tank',
      //   selectionActionName: '',
      //   actionSettings: {
      //     originTank: '',
      //     destinationTank: '',
      //     duration: 0,
      //     volume: 0.0,
      //   },
      //   destinationTankOptions: [
      //     {
      //       id: 1,
      //       description: 'Mixing unit',
      //       ros_irrigation_id: 'mixing-unit',
      //     },
      //     {
      //       id: 2,
      //       description: 'Waste',
      //       ros_irrigation_id: 'waste',
      //     },
      //   ],
      // },
      // {
      //   id: 4,
      //   actionName: 'circulate_tank',
      //   selectionActionName: '',
      //   actionSettings: {
      //     originTank: '',
      //     duration: 0,
      //     volume: 0.0,
      //   },
      // },
    ],
  },
}
